


























































import { Component, Vue } from 'vue-property-decorator';
import { NavButton } from '@/components/global';
import { pagePath } from '@/utils';
import { EmployeurOffre, Formule } from '@/models';
import { contextEmployeur, garanties } from '@/store';
import { TableGaranties } from '@/components';
import { emitScrollToElement } from '@projetlucie/lc-front-sdk-old';
import { EventBus } from '@/event-bus';

@Component({
  methods: {},
  components: { NavButton, TableGaranties }
})
export default class Garanties extends Vue {
  private offre: EmployeurOffre | null = null;
  private search = '';
  private partCollaborateurLabel = this.$t('garanties.partCollaborateurLabel');
  private formules: Formule[] | null = garanties.formules;
  private refs: any = this.$refs;
  private btnRetourId = 'employeur-couverture-garanties-btn-retour';

  public goBack() {
    this.$router.push(pagePath.Couvertures);
  }

  get contextEmployeur() {
    return contextEmployeur.getEmployeur;
  }

  backToTop() {
    const element = document.getElementById(this.btnRetourId);
    emitScrollToElement(EventBus, element as HTMLElement);
  }

  created() {
    if (!this.contextEmployeur) return;
    this.offre = this.contextEmployeur.infoOffreEmployeur;
  }

  mounted() {
    this.backToTop();
  }
}
